import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="SOMERA" link="https://github.com/sergialca/somera" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%);" mdxType="ProjectCard">
  This project made with gatsby it's a website to introduce a new craft beer.
    </ProjectCard>
    <ProjectCard title="BINANCE PORTFOLIO" link="https://github.com/sergialca/binance-portfolio" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
  This project allows you to create a portfolio of crypto assets getting data from Binance API.
    </ProjectCard>
    <ProjectCard title="TAPTIME" link="https://github.com/sergialca/taptime" bg="linear-gradient(to right, rgb(212, 20, 90) 0%, rgb(251, 176, 59) 100%)" mdxType="ProjectCard">
  Taptime is a website for booking rooms, in a specific interval of time, in a choosen day.
  It is made with React and it has a Parse Server with MongoDB and a node.js server. Feel free to use the demo.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      