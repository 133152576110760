import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About me`}</h1>
    <p>{`I have been working as a frontend developer for more than 3 years and I have learnt that join a team and get involved in a digital project, make it bigger or help to make it better, make sure that the product follows the design and deliver the best user experience to the final user and explore new technologies that allow me do more with less, is what I love.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      